import React, {useState} from 'react';
import {extractTypeAndLabel, FormDropdown, FormEditor, FormInput, SubHeading} from '../Shared/CreateForm';
import {Color, FontSize, OFFICE_USE, OfficeUseOrdering, MS_PER_S} from "../../constants";
import { RETAIL_PRICE, DELIVERY_PRICE, DISCOUNT, SETUP_FEE, ACTUAL_HRS, HOURLY_RATE } from "../../office-use-constants";
import {StyledCheckbox} from "./StyledCheckbox";
import styled from 'styled-components';
import {breakpoint} from "../../breakpoints";
import { DateTimeFormInput, DateInputType } from './DateTimeFormInput';
import { Repeater } from './Repeater/Repeater';
import structuredClone from '@ungap/structured-clone';
const OFFICE_USE_PROJECT_FIELDS = [RETAIL_PRICE, DELIVERY_PRICE, DISCOUNT, SETUP_FEE, ACTUAL_HRS, HOURLY_RATE];

export const CheckBoxContainer = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    ${breakpoint('medium up')} {
        height: 120px;
        padding-bottom: 0;
    }
    flex-flow: column wrap;
    padding-top: .75rem;
    padding-bottom: 1rem;
`;

export const MultiSelectContainer = styled.div`
    width: 100%;
`;

export const MultiSelectLabel = styled.label`
  display: block;
  color: ${Color.nile50};
  font-size: ${FontSize.body2};
`

export const getNumber = (value) => {
    if (!isNaN(parseFloat(value)) && isFinite(value)){
        return parseFloat(value);
    }
    return "";
};

export const MetadataForm = (props) => {
    const initializeValues = (props) => {
        return extractTypeAndLabel(props.metadata.meta_value);
    }
    const [isVisible, setVisible] = useState(false);
    const [values, setValues] = useState(initializeValues(props));
    const [, setRerender] = useState(false);
    const toggleVisible = (e) => {
        e.preventDefault();
        setVisible(!isVisible);
    };
    const saveValue = (e, type, label, value, options) => {
        if(e !== null && !e._isAMomentObject){
            e.stopPropagation();
        }
        if(value===undefined){
           value=null;
        }
        let newValue;
        if(type==='multiselect'){
            const oldValue = values.find((e) => e.label === label)
            let selected;
            if (oldValue.value.includes(value)) {
                selected = oldValue.value.filter(v => v !== value);
            } else {
                selected = ([...oldValue.value, value]);
            }
            newValue = {
                "label": label,
                "value": selected,
                "type": type,
            }
        }
        else{
            newValue = {
                "label": label,
                "value": value,
                "type": type,
            }
        }
        if(type==='dropdown' || type==='multiselect'){
            newValue['options']=options;
        }

        let newValues = [...values];
        const oldValue = newValues.find((e) => e.label === label);
        oldValue === undefined ? newValues.push(newValue) : Object.assign(oldValue, newValue);

        setValues(newValues);

        updateMetaValues(newValues);
    }

    const updateMetaValues = (fields) => {
        
        fields.forEach(element=>{
            if(element.type==='repeater'){
                if(element.value.length>0&&!Array.isArray(element.value[0].value)){
                    element.value=[{value:element.value}];
                }
            }
        });
        const metaFields = {
            "meta_name": props.metadata.meta_name,
            "meta_value": fields,
            "is_changed": true,
        }
        let newMetaFields = [...props.metaFields];
        const oldMetaField = newMetaFields.find((e) => e.meta_name === props.metadata.meta_name);
        oldMetaField === undefined ? newMetaFields.push(metaFields) : Object.assign(oldMetaField, metaFields);

        props.setMetaFields(newMetaFields);
    }

    const findDateValue = (field) => {
        return values.find((e) => e.label === field.label) && values.find((e) => e.label === field.label).value > 0 ? values.find((e) => e.label === field.label).value * MS_PER_S : null;
    };
    //find the label append value to its value var
    const addRepeater=(label,_value, index)=>{
        _value=structuredClone(_value);
        _value.value.forEach(element=>{
            if(element.type==='paragraph' || element.type==='string' ||element.type==='file'){
                element.value='';
            }else{
                element.value=0;
            }
            
        });
        let metaVal=props.metaFields.find(element=>element.meta_name === props.metadata.meta_name).meta_value[index].value;
        props.metaFields.find(element=>element.meta_name === props.metadata.meta_name).meta_value[index].value=[...metaVal, {value:_value.value}];
        setRerender(prev=>!prev);
    };
    const saveRepeaterField=(field, val)=>{
        field.value=val;
        updateMetaValues(values);
        setRerender(prev=>!prev);
    };
    const saveRepeaterFieldInt=(field, val)=>{
        if(val&&typeof val ==='string'){
            val=parseInt(val);
        }else if(!val){
            val=0;
        }
        field.value=parseInt(val);
        updateMetaValues(values);
        setRerender(prev=>!prev);
    };
    return(
        <>
            <SubHeading
                title={props.metadata.meta_name}
                unicodeIcon = {isVisible ? '\f0d8' : '\f0d7'}
                justifyContent='flex-start'
                onClick={(e) => toggleVisible(e)}
            />
            {isVisible && props.metadata.meta_name !== OFFICE_USE &&
                props.metadata.meta_value.map((field, index)=> {
                    switch(field.type){
                        case 'string':
                            return(
                                <FormInput
                                    label={field.label}
                                    key={field.label}
                                    placeholder={field.value}
                                    width='49%'
                                    value={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                    onChange={(e) => saveValue(e, field.type, field.label, e.target.value, [])}
                                />
                            )
                        case 'int':
                        case 'float':
                            return(
                                <FormInput
                                    type="number"
                                    label={field.label}
                                    key={field.label}
                                    placeholder={field.value}
                                    width='49%'
                                    value={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                    onChange={(e) => saveValue(e, field.type, field.label, getNumber(e.target.value), [])}
                                />
                            )
                        case 'percent':
                            return(
                                <FormInput
                                    type="number"
                                    label={field.label}
                                    key={field.label}
                                    placeholder={field.value}
                                    width='49%'
                                    max={100}
                                    min={0}
                                    value={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                    onChange={(e) => saveValue(e, field.type, field.label, getNumber(e.target.value), [])}
                                />
                            )
                        case 'bool':
                            return(
                                <FormDropdown
                                    label={field.label}
                                    key={field.label}
                                    selectedOption={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                    values={["false","true"]}
                                    options={["No","Yes"]}
                                    width='49%'
                                    onChange={(e) => saveValue(e, field.type, field.label, e.target.value, [])}
                                />
                            )
                        case 'dropdown':
                            return(
                                <FormDropdown
                                    label={field.label}
                                    key={field.label}
                                    selectedOption={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                    options={field.options.map(option => option.display)}
                                    values={field.options.map(option => option.value)}
                                    width='49%'
                                    placeholder={'-select-'}
                                    onChange={(e) => saveValue(e, field.type, field.label, e.target.value, field.options)}
                                />
                            )
                        case 'multiselect':
                            return(
                                <MultiSelectContainer key={field.label}>
                                    <MultiSelectLabel>{field.label}</MultiSelectLabel>
                                    <CheckBoxContainer>

                                        {
                                            field.options.map(option => (

                                                <StyledCheckbox
                                                    checkboxId={option.value}
                                                    paddingTop='0'
                                                    key={option.value}
                                                    checked={values.find((e) => e.label === field.label) ? values.find((e) => e.label === field.label).value.includes(option.value) : false}
                                                    width='50%'
                                                    label={option.display}
                                                    onChange={(e) => {
                                                        saveValue(e, field.type, field.label, option.value, field.options)
                                                    }}
                                                />
                                            ))
                                        }
                                    </CheckBoxContainer>
                                </MultiSelectContainer>
                            )
                        case 'date':
                            return(
                                <DateTimeFormInput
                                    label={field.label}
                                    key={field.label}
                                    width='49%'
                                    format='MM/DD/YYYY'
                                    placeholder='mm/dd/yyyy'
                                    type={DateInputType}
                                    value={findDateValue(field)}
                                    onChange={(e) => saveValue(e, field.type, field.label, e?.unix(), [])}
                                />
                            )
                        case 'paragraph':
                            return(
                                <FormEditor
                                    label={field.label}
                                    key={field.label}
                                    value={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                    id={field.label}
                                    name={field.label}
                                    onChange={(content, editor) => saveValue(null, field.type, field.label, content, [])}
                                />
                            )
                        case 'repeater':
                            let currentRepeater=props.currentTemplate.meta_value.find(element=>element.label===field.label);
                            let curValue=props.metaFields.find(element=>element.meta_name === props.metadata.meta_name).meta_value[index];
                            if(!curValue.value){
                                currentRepeater.value=[...currentRepeater.value];
                                curValue.value={...currentRepeater};
                            }else{
                                curValue.value.forEach(element=>{
                                    element.value.forEach(innerElement=>{
                                        if(typeof innerElement.value === 'undefined'){
                                            if(innerElement.type==='paragraph' || innerElement.type==='string' ||innerElement.type==='file'){
                                                innerElement.value='';
                                            }else{
                                                innerElement.value=0;
                                            }
                                        }
                                    });
                                });
                            }
                            return(
                                <Repeater
                                    key={`${field.label}-${index}`}
                                    values={values}
                                    repeaterIndex={index}
                                    findDateValue={findDateValue}
                                    addRepeater={()=>addRepeater(field.label,currentRepeater, index)}
                                    form={field}
                                    currentTemplate={curValue}
                                    label={field.label}
                                    value={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                    id={field.label}
                                    name={field.label}
                                    saveRepeaterField={saveRepeaterField}
                                    saveRepeaterFieldInt={saveRepeaterFieldInt}
                                />
                            );
                        default:
                            return (<React.Fragment key={field.label}></React.Fragment>)
                }})}
                {
                    isVisible && props.metadata.meta_name === OFFICE_USE && Object.keys(OfficeUseOrdering).filter(fieldName => {
                        // If this is a field associated with the project then include it since this won't show up in metadata
                        if (OFFICE_USE_PROJECT_FIELDS.includes(fieldName)) {
                            return true;
                        }
                        return props.metadata.meta_value.some(field => field.label === fieldName);
                    }).map(fieldName => {
                        const field = props.metadata.meta_value.find(field => field.label === fieldName);
                        if (OFFICE_USE_PROJECT_FIELDS.includes(fieldName)) {
                            switch(fieldName) {
                                case RETAIL_PRICE:
                                    return (
                                        <FormInput
                                            key={fieldName}
                                            type='number'
                                            label='Retail Price'
                                            placeholder='0.00'
                                            width='49%'
                                            value={props.retailPrice ? props.retailPrice : ""}
                                            onChange={(e) => props.setRetailPrice(getNumber(e.target.value))}
                                        />
                                    );
                                case DELIVERY_PRICE:
                                    return (
                                        <FormInput
                                            key={fieldName}
                                            type='number'
                                            label='Delivery Price'
                                            placeholder='0.00'
                                            width='49%'
                                            value={props.deliveryPrice ? props.deliveryPrice : ""}
                                            onChange={(e) => props.setDeliveryPrice(getNumber(e.target.value))}
                                        />
                                    );
                                case SETUP_FEE:
                                    return (
                                        <FormInput
                                            key={fieldName}
                                            type='number'
                                            label='Setup Fee'
                                            placeholder='0.00'
                                            width='49%'
                                            value={props.setupFee ? props.setupFee : ""}
                                            onChange={(e) => props.setSetupFee(getNumber(e.target.value))}
                                        />
                                    );
                                case DISCOUNT:
                                    return (
                                        <FormInput
                                            key={fieldName}
                                            type='number'
                                            label='Discount %'
                                            placeholder='0'
                                            width='49%'
                                            value={props.discount ? props.discount : ""}
                                            max={100}
                                            min={0}
                                            onChange={(e) => props.setDiscount(getNumber(e.target.value))}
                                        />
                                    );
                                case ACTUAL_HRS:
                                    return (
                                        <FormInput
                                            key={fieldName}
                                            type='number'
                                            label='Actual Hours'
                                            placeholder='0.00'
                                            width='49%'
                                            value={props.actualHours ? props.actualHours : ""}
                                            onChange={(e) => props.setActualHours(getNumber(e.target.value))}
                                        />
                                    );
                                case HOURLY_RATE:
                                    return (
                                        <FormInput
                                            key={fieldName}
                                            type='number'
                                            label='Hourly Rate'
                                            placeholder='0.00'
                                            width='49%'
                                            value={props.hourlyRate ? props.hourlyRate : ""}
                                            onChange={(e) => props.setHourlyRate(getNumber(e.target.value))}
                                        />
                                    );
                                default:
                                    throw new Error(`The office use field ${fieldName} is not accounted for, please update the code.`);
                            }
                        }
                        switch(field.type){
                            case 'string':
                                return(
                                    <FormInput
                                        label={field.label}
                                        key={field.label}
                                        placeholder={field.value}
                                        width='49%'
                                        value={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                        onChange={(e) => saveValue(e, field.type, field.label, e.target.value, [])}
                                    />
                                )
                            case 'int':
                            case 'float':
                                return(
                                    <FormInput
                                        type="number"
                                        label={field.label}
                                        key={field.label}
                                        placeholder={field.value}
                                        width='49%'
                                        value={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                        onChange={(e) => saveValue(e, field.type, field.label, getNumber(e.target.value), [])}
                                    />
                                )
                            case 'percent':
                                return(
                                    <FormInput
                                        type="number"
                                        label={field.label}
                                        key={field.label}
                                        placeholder={field.value}
                                        width='49%'
                                        max={100}
                                        min={0}
                                        value={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                        onChange={(e) => saveValue(e, field.type, field.label, getNumber(e.target.value), [])}
                                    />
                                )
                            case 'bool':
                                return(
                                    <FormDropdown
                                        label={field.label}
                                        key={field.label}
                                        selectedOption={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                        values={["false","true"]}
                                        options={["No","Yes"]}
                                        width='49%'
                                        onChange={(e) => saveValue(e, field.type, field.label, e.target.value, [])}
                                    />
                                )
                            case 'dropdown':
                                return(
                                    <FormDropdown
                                        label={field.label}
                                        key={field.label}
                                        selectedOption={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                        options={field.options.map(option => option.display)}
                                        values={field.options.map(option => option.value)}
                                        width='49%'
                                        placeholder={field.value}
                                        onChange={(e) => saveValue(e, field.type, field.label, e.target.value, field.options)}
                                    />
                                )
                            case 'multiselect':
                                return(
                                    <MultiSelectContainer key={field.label}>
                                        <MultiSelectLabel>{field.label}</MultiSelectLabel>
                                        <CheckBoxContainer>
    
                                            {
                                                field.options.map(option => (
    
                                                    <StyledCheckbox
                                                        checkboxId={option.value}
                                                        paddingTop='0'
                                                        key={option.value}
                                                        checked={values.find((e) => e.label === field.label) ? values.find((e) => e.label === field.label).value.includes(option.value) : false}
                                                        width='50%'
                                                        label={option.display}
                                                        onChange={(e) => {
                                                            saveValue(e, field.type, field.label, option.value, field.options)
                                                        }}
                                                    />
                                                ))
                                            }
                                        </CheckBoxContainer>
                                    </MultiSelectContainer>
                                )
                            case 'date':
                                return(
                                    <DateTimeFormInput
                                        label={field.label}
                                        key={field.label}
                                        width='49%'
                                        format='MM/DD/YYYY'
                                        placeholder='mm/dd/yyyy'
                                        type={DateInputType}
                                        value={findDateValue(field)}
                                        onChange={(e) => saveValue(e, field.type, field.label, e?.unix(), [])}
                                    />
                                )
                            case 'paragraph':
                                return(
                                    <FormEditor
                                        label={field.label}
                                        key={field.label}
                                        value={values.find((e) => e.label === field.label)? values.find((e) => e.label === field.label).value : ""}
                                        id={field.label}
                                        name={field.label}
                                        onChange={(content, editor) => saveValue(null, field.type, field.label, content, [])}
                                    />
                                )
                            default:
                                return (<React.Fragment key={field.label}></React.Fragment>)
                        }
                    })
                }
        </>
    )
}
