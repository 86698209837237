import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useHistory, Link} from 'react-router-dom';
import {
    ACCOUNT_REP,
    CLIENT,
    Color,
    CONTACT_DATE,
    CONTACT_NAME,
    CREATED,
    DATE_ENTERED,
    DESIGN_DUE_DATE,
    DUE_DATE,
    FontSize,
    HOURS,
    NAME,
    NAV_PROJECTS,
    PROJECT,
    PROJECT_ARCHIVE_STATUS,
    PROJECT_CREATE_PERM,
    PROJECT_SEARCH_ASSIGNEE,
    PROJECT_SEARCH_CLIENT,
    PROJECT_SEARCH_CLIENT_NAME,
    PROJECT_SEARCH_ORDER_BY,
    PROJECT_SEARCH_PAGE,
    PROJECT_SEARCH_STATUS,
    PROJECT_SEARCH_TYPE,
    PROJECT_SEARCH_VALUE,
    PROJECT_UPDATE_PERM,
    QUOTE_REJECTED_STATUS,
    QUOTED_HOURS_MIN,
    STATUS,
    TIER,
    TYPE,
    UPDATED,
    JOB_NUMBER,
    CLIENT_NAME,
    NAV_CLIENTS
} from '../../constants';
import {useGetData} from '../../projlibs/api.js';
import {FlexTableController} from '../Shared/FlexTableController';
import {FormattedDate} from '../Shared/FormattedDate';
import {StyledText} from '../Shared/StyledText';
import {Button} from '../Shared/Button';
import {SearchInput} from '../Shared/SearchInput';
import {ControlledFilterDropdown, Dropdown} from '../Shared/Dropdown';
import {AssignedUser} from '../Shared/AssignedUser';
import {ProjectType} from '../Shared/ProjectType';
import {breakpoint} from '../../breakpoints';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons';
import {CreateProjectsForm} from './CreateProjectsForm';
import {CreateFormButton} from '../Shared/OverlayViewButton';
import {PageController} from '../Shared/PageController';
import {orderTypes} from '../../projlibs/helperFunctions';
import {PageIndex} from '../Shared/PageIndex';
import {DataContext} from '../../data-context';
import {ClientSearchDropdown} from '../Clients/ClientSearchDropdown';
import {faCopy} from '@fortawesome/free-solid-svg-icons';
import {CopyProjectForm} from './CopyProjectForm';
import {Helmet} from 'react-helmet';
import {API} from "../../projlibs/api";
import {success} from "../../projlibs/feedback";
import { StyledLink } from '../Shared/StyledLink';
import {getNumber} from "../Shared/MetadataForm";
import { TableCalendarButton } from './TableCalendarButton';

// Projects: displays all projects in a list.  Has search and filter functionality
// Props:
// projects: array of projects.
const ProjectsView = styled.div`
	background-color: ${Color.white};
`;

const ProjectHeader = styled.div`
	padding: 2rem 1.5rem;
	background-color: ${Color.nile5};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const TableHeader = styled.div`
	display: inline-flex;
	width: 100%;
	flex-direction: column;
	background-color: ${Color.white};
	justify-content: space-between;
	${breakpoint('large')} {
		flex-direction: row;
	}
`;

export const FilterControlsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 1rem 0;
	margin-right: 0.5rem;
	${breakpoint('medium up')} {
		margin: 0;
		flex-wrap: wrap;
		flex-direction: row;
	}
`;

const StyledFilterDropdown = styled(ControlledFilterDropdown)`
	flex: 1;
	width: 100%;
	margin: 0.25rem 0.4rem;
	${breakpoint('medium up')} {
		margin: 0.25rem 0.4rem;
		width: fit-content;
		max-width: 200px;
	}
`;

export const HoverUnderlineName = styled(StyledText)`
	:hover {
		text-decoration: underline;
	}
`;

const ProjectListView = styled.div`
	position: relative;
`;

export function getDesignDueDate(project) {
	//NOTE: this is now a hybrid property
	if(project.hasOwnProperty('design_due_date')){
		if(getNumber(project.design_due_date)){
			return project.design_due_date;
		}
	}
	return '';
}

const generateUpdateProjectUrl = (projectId) => `/project/${projectId}`;

const UpdateProjectStatus = (e, newStatus, type, projectId) => {
	e.stopPropagation();
	API.put(generateUpdateProjectUrl(projectId), {'data' :{ 'project_status': newStatus, 'project_type': type }}).then(() => success("Status Updated Successfully")).catch(API.default_error_handler);
};

const UpdateProjectDateDue = (dateDue, projectId) => {
	API.put(generateUpdateProjectUrl(projectId), {'data' :{ date_due: dateDue  }}).then(() => success("Date Due Updated Successfully")).catch(API.default_error_handler);
};


export function getQuotedHours(project) {
	//NOTE: this is now a hybrid property
	if(project.hasOwnProperty('quoted_hours_min')){
		if(getNumber(project.quoted_hours_min)){
			return project.quoted_hours_min;
		}
	}
	return '';
}

function SetupTableData(projects, statuses, userPerms, allowingCopy, setRefresh) {
	let history = useHistory();
	let tableData = {
		titles: allowingCopy ? [
			TYPE,
			CREATED,
			NAME,
			CLIENT_NAME,
			'Assigned',
			'Status',
			DUE_DATE,
			'',
			'',
		]:
		[
			TYPE,
			CREATED,
			NAME,
			CLIENT_NAME,
			'Assigned',
			'Status',
			DUE_DATE,
			'',
		],
		rows: projects?.map((project) => {
			const typeStatuses = statuses[project.Project_Type?.name];
			const statusValues = typeStatuses ? typeStatuses.filter(status => status !== project?.Project_Status?.name) : [];
			let rowElements = [
				{
					sortValue: project.Project_Type?.name,
					element: (
						<ProjectType
							viewColor={project.Project_Type?.color}
							title={project.Project_Type?.name}
						/>
					),
				},
				{
					minWidth: '80px',
					sortValue: project.created_at,
					element: (
						<FormattedDate
							date={project.created_at}
							color={Color.nile}
						/>
					),
				},
				{
					sortValue: project?.name,
					element: (
						<Link to={`${NAV_PROJECTS}/${project?.project_id}`} style={{ textDecoration: 'none' }}>
							<HoverUnderlineName
								color={Color.nile}
								fontSize={FontSize.body1}
								marginBottom='4px'>
								{project?.name}
							</HoverUnderlineName>
						</Link>
					),
				},
				{
					sortValue: project?.Client?.name,
					element: (
						<StyledLink to={`${NAV_CLIENTS}/${project?.client_id}`}>
							{project?.Client?.name}
						</StyledLink>
					),
				},
				{
					minWidth: '80px',
					element: (
						<AssignedUser
							users={project?.Manager_User ? [...project?.assigned_users.slice(0,3), project?.Manager_User] : project?.assigned_users.slice(0,3)}
						/>
					),
				},
				{
					sortValue: project.Project_Status?.name,
					element: (
						<Dropdown
							minViewWidth='100%'
							disabled={!userPerms[PROJECT_UPDATE_PERM]}
							placeholderValue={project.Project_Status?.name}
							options={statusValues}
							values={statusValues}
							onChange={(e) => UpdateProjectStatus(e, e.target.value, project.Project_Type?.name, project.project_id)}
							placeholder={project.Project_Status?.name}
						/>
					),
				},
				{
					sortValue: project.date_due,
					element: (
						<TableCalendarButton project={project} UpdateProjectDateDue={UpdateProjectDateDue} setRefresh={setRefresh}/>
					),
				},
			];
			if (allowingCopy) {
				rowElements = [...rowElements,
				{
					minWidth: 'fit-content',
					element: (
						<CreateFormButton hoverBackground={Color.fuel} iconXOffset='5px' icon={faCopy} title='Copy'>
							<CopyProjectForm project={project} />
						</CreateFormButton>
					),
				},
				{
					element: (
						<Button
							fontSize='1.5rem'
							icon={faAngleRight}
							iconSize='lg'
							onClick={(event) =>
								history.push(
									NAV_PROJECTS + '/' + project.project_id
								)
							}
						/>
					),
				}];
			} else {
				rowElements = [...rowElements, {
					element: (
						<Button
							fontSize='1.5rem'
							icon={faAngleRight}
							iconSize='lg'
							onClick={(event) =>
								history.push(
									NAV_PROJECTS + '/' + project.project_id
								)
							}
						/>
					),
				}];
			}
			return rowElements;
		}),
	};

	return tableData;
}

const updateOrderBy = (e, title) => {
	switch(title){
		case TYPE:
			return "order_by=project_type:" + e.currentTarget.dataset.sortorder;
		case CREATED:
			return "order_by=created_at:" + e.currentTarget.dataset.sortorder;
		case NAME:
		case PROJECT:
		case CLIENT:
			return "order_by=name:" + e.currentTarget.dataset.sortorder;
		case CLIENT_NAME:
			return "order_by=client_name:" + e.currentTarget.dataset.sortorder;
		case JOB_NUMBER:
			return "order_by=project_id:" + e.currentTarget.dataset.sortorder;
		case 'Last Updated':
		case UPDATED:
			return "order_by=modified_at:" + e.currentTarget.dataset.sortorder;
		case 'Date Due':
		case DUE_DATE:
			return "order_by=date_due:" + e.currentTarget.dataset.sortorder;
		case CONTACT_NAME:
			return "order_by=contact_name:" + e.currentTarget.dataset.sortorder;
		case TIER:
			return "order_by=client_tier:" + e.currentTarget.dataset.sortorder;
		case ACCOUNT_REP:
			return "order_by=account_rep_name:" + e.currentTarget.dataset.sortorder;
		case STATUS:
			return "order_by=project_status:" + e.currentTarget.dataset.sortorder;
		case HOURS:
			return "order_by=hours:" + e.currentTarget.dataset.sortorder;
		case 'Quoted Hrs':
		case QUOTED_HOURS_MIN:
			return "order_by=meta:Quoted Hours Min:" + e.currentTarget.dataset.sortorder;
		case CONTACT_DATE:
			return "order_by=date_initial_contact:" + e.currentTarget.dataset.sortorder;
		case DATE_ENTERED:
			return "order_by=entered_at:" + e.currentTarget.dataset.sortorder;
		case DESIGN_DUE_DATE:
			return "order_by=meta:Design Due Date:" + e.currentTarget.dataset.sortorder;
		default:
			return "";
	}
}

export const getURL = ({location='project', client = "", assignee = "", searchToSubmit = "", status = "", type = "", client_id = "", orderBy = "", archive = false, rejected = false}={}) => {
	let url = '/' + location + '/search?';
	if(client !== "" && client !== "placeholder"){
		url += "match_client_id=" + client + "&";
	}
	if(client_id !== undefined && client_id !== ""){
		url += "match_client_id=" + client_id + "&";
	}
	if(assignee !== "" && assignee !== "placeholder"){
		url += "match_pm_or_assigned_user=" + assignee + "&";
	}
	if(searchToSubmit !== ""){
		url += "match_name=" + searchToSubmit + "&";
	}
	if(status !== "" && status !== "placeholder"){
		url += "match_project_status=" + status + "&";
	}else{
		if(archive) {
			url += "match_project_status=" + encodeURIComponent(PROJECT_ARCHIVE_STATUS) + "&";
		}
		else if(rejected){
			url += "match_project_status=" + encodeURIComponent(QUOTE_REJECTED_STATUS) + "&";
		}
		else{
			if(location === 'project'){
				url += "exclude_project_statuses=" + encodeURIComponent(PROJECT_ARCHIVE_STATUS) + "&";
			}
			else{
				url += "exclude_project_statuses=" + encodeURIComponent(QUOTE_REJECTED_STATUS) + "&";
			}
		}
	}
	if(type !== "" && type !== "placeholder"){
		url += "match_project_type=" + type + "&";
	}
	if(orderBy !== ""){
		url += orderBy + "&";
	} else {
		url += "order_by=created_at:asc&";
	}
	//full=true is needed to include project manager information
	url += 'full=true&';
	return url;
};

export const updateSorting = (e, title, setter) => {
	setter(updateOrderBy(e, title));
	e.currentTarget.dataset.sortorder = e.currentTarget.dataset.sortorder === 'asc' ? 'desc' : 'asc';
}

const StyledClientSearchDropdown = styled(ClientSearchDropdown)`
	input {
		height: fit-content;
	}
	flex: 1;
	width: 100%;
	margin-top: 0.4rem;
	margin-right: 0.4rem;
	margin-bottom: auto;
	margin-left: 0.8rem;
	${breakpoint('medium up')} {
		margin-right: 0.1rem;
		margin-top: auto;
		min-width: 200px;
		width: fit-content;
		max-width: 200px;
	}
`;

const NoResultsText = styled.h3`
	color: ${Color.nile50};
	align-self: center;
	text-align: center;
`;

export const Projects = (props) => {
	const localStorage = window.localStorage;
	const { userPerms, projectStatuses, allStatuses } = useContext(DataContext);
	const [searchValue, setSearchValue] = useState((props.client_id || props.archive) ? '' : localStorage.getItem(PROJECT_SEARCH_VALUE) ? localStorage.getItem(PROJECT_SEARCH_VALUE) : '');
	const [searchToSubmit, setSearchToSubmit] = useState((props.client_id || props.archive) ? '' : localStorage.getItem(PROJECT_SEARCH_VALUE) ? localStorage.getItem(PROJECT_SEARCH_VALUE) : '');
	const [type, setType] = useState((props.client_id || props.archive) ? '' : localStorage.getItem(PROJECT_SEARCH_TYPE) ? localStorage.getItem(PROJECT_SEARCH_TYPE) : '');
	const [client, setClient] = useState((props.client_id || props.archive) ? '' : localStorage.getItem(PROJECT_SEARCH_CLIENT) ? localStorage.getItem(PROJECT_SEARCH_CLIENT) : '');
	const [assignee, setAssignee] = useState((props.client_id || props.archive) ? '' : localStorage.getItem(PROJECT_SEARCH_ASSIGNEE) ? localStorage.getItem(PROJECT_SEARCH_ASSIGNEE) : '');
	const [status, setStatus] = useState((props.client_id || props.archive) ? '' : localStorage.getItem(PROJECT_SEARCH_STATUS) ? localStorage.getItem(PROJECT_SEARCH_STATUS) : '');
	const [orderBy, setOrderBy] = useState((props.archive || props.archive) ? 'order_by=created_at:desc' : (localStorage.getItem(PROJECT_SEARCH_ORDER_BY) ? localStorage.getItem(PROJECT_SEARCH_ORDER_BY) : ''));
	const [currentPage, setCurrentPage] = useState((props.client_id || props.archive) ? 0 : localStorage.getItem(PROJECT_SEARCH_PAGE) ? parseInt(localStorage.getItem(PROJECT_SEARCH_PAGE)) : 0);
	const [numPages, setNumPages] = useState(0);
	const [resultCount, setResultCount] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');
	const [clientName, setClientName] = useState(localStorage.getItem(PROJECT_SEARCH_CLIENT_NAME) ? localStorage.getItem(PROJECT_SEARCH_CLIENT_NAME) : '');
	const [refresh, setRefresh] = useState(false);
	const [{ data }, setUrl] = useGetData(getURL({
		client_id: props.client_id,
		archive: props.archive,
		type: type,
		client: client,
		assignee: assignee,
		status: props.archive ? PROJECT_ARCHIVE_STATUS : status,
		searchToSubmit: searchToSubmit,
		orderBy: orderBy,
	}),[],refresh);

	const [{ data: projectTypes }] = useGetData("/project/type");
	// TODO: These types and status should actually be fetched at the beginning as the app is starting
	// Ideally we would fetch these and then store them in React Context
	let types = projectTypes.Project_Type?.map(type => type.name)
	types = orderTypes(types);

	const statuses = type === '' ? allStatuses?.filter(status => status !== PROJECT_ARCHIVE_STATUS) : projectStatuses[type]?.filter(status => status !== PROJECT_ARCHIVE_STATUS);

	const [{ data: userData }] = useGetData("/user?page_size=");
	const users = userData.User?.map(user => user.full_name);
	const user_ids = userData.User?.map(user =>user.user_id.toString());

	const tableData = SetupTableData(data?.Project, projectStatuses, userPerms, props.allowingCopy, setRefresh);

	useEffect(() => {
		const localStorage = window.localStorage;
		const saveSearchSelections = (hasChanged = false) => {
			localStorage.setItem(PROJECT_SEARCH_TYPE, type);
			localStorage.setItem(PROJECT_SEARCH_CLIENT, client);
			localStorage.setItem(PROJECT_SEARCH_CLIENT_NAME, clientName);
			localStorage.setItem(PROJECT_SEARCH_ASSIGNEE, assignee);
			localStorage.setItem(PROJECT_SEARCH_STATUS, status);
			localStorage.setItem(PROJECT_SEARCH_ORDER_BY, orderBy);
			localStorage.setItem(PROJECT_SEARCH_VALUE, searchToSubmit);
			localStorage.setItem(PROJECT_SEARCH_PAGE, hasChanged ? 0 : currentPage);
		};
		let url = getURL({
			type: type,
			client: client,
			assignee: assignee,
			status: props.archive ? PROJECT_ARCHIVE_STATUS : status,
			searchToSubmit: searchToSubmit,
			client_id: props.client_id,
			orderBy: orderBy,
			archive: props.archive,
		});

		//if the url is different then the query has changed and we want to go back to the first page
		if(url !== searchQuery){
			if (!window.localStorage.getItem(PROJECT_SEARCH_PAGE)) {
				setCurrentPage(0);
			}
			if (!props.archive && !props.client_id) {
				saveSearchSelections(true);
			}
		} else {
			if (!props.archive && !props.client_id) {
				saveSearchSelections(false);
			}
		}
		setSearchQuery(url);
		url += "page=" + currentPage;
		setUrl(url);
	}, [type, client, assignee, status, currentPage, setUrl, orderBy, searchToSubmit, props.client_id, searchQuery, props.archive, clientName]);

	useEffect(() => {
		setNumPages(data.page_count);
		setResultCount(data.result_count);
	}, [data]);

	const handleSearchSubmit = (e) => {
		setSearchToSubmit(searchValue);
		e.preventDefault();
	}

	return (
		<ProjectsView>
			<Helmet>
				<meta name="description" content='Projects' />
			</Helmet>
			{props.client_id === undefined && (
				<ProjectHeader>
					<StyledText
						as='h1'
						color={Color.nile}
						fontSize={FontSize.header2}>
						Projects
					</StyledText>
					{
						userPerms[PROJECT_CREATE_PERM] &&
						<CreateFormButton title="New Project">
							<CreateProjectsForm/>
						</CreateFormButton>
					}
				</ProjectHeader>
			)}
			<TableHeader>
				<FilterControlsWrapper>
					<SearchInput
						containerStyle={{ flex: '1', minWidth: '200px', width: '100%', margin: '0.5rem 0rem 0.5rem 0.75rem' }}
						placeholder={'Search projects'}
						borderColor={Color.nile15}
						hoverBorderColor={Color.nile5}
						value={searchValue}
						onChange={e => setSearchValue(e.target.value)}
						onSubmit={e => handleSearchSubmit(e)}
					/>
					{props.client_id === undefined && (
						<StyledClientSearchDropdown
							returnName
							mode='edit'
							project={client ? { project_id: 'Filter', client_id: parseInt(client), Client: {name: clientName} } : undefined}
							width='64%'
							height='42px'
							placeholder='Client'
							onChange={(value) => {
								setClient(value.valueField ? value.valueField : '');
								setClientName(value.textField ? value.textField : '');
							}}
						/>
					)}
					<StyledFilterDropdown
						value={type}
						placeholder='Type'
						options={types}
						values={types}
						onChange={(e) => setType(e.target.value)}
					/>
					<StyledFilterDropdown
						value={assignee}
						placeholder='Assignee'
						options={users}
						values={user_ids}
						onChange={(e) => setAssignee(e.target.value)}
					/>
					{
						!props.archive &&
						<StyledFilterDropdown
							value={props.archive ? PROJECT_ARCHIVE_STATUS : status}
							placeholder='Status'
							options={statuses}
							values={statuses}
							onChange={(e) => setStatus(e.target.value)}
						/>
					}
				</FilterControlsWrapper>
				<PageIndex resultCount={resultCount} currentPage={currentPage} />
			</TableHeader>
			<ProjectListView>
				{
					// We wait for status to be populated before rendering the table in order for the status dropdowns
					// to render properly, otherwise it is a race condition to see if the status get request finishes first.
					statuses &&
					<FlexTableController tableData={tableData} sort={(e, title) => updateSorting(e,title, setOrderBy)}/>
				}
			</ProjectListView>
			{
				numPages === 0 &&
				<NoResultsText>No Project Results</NoResultsText>
			}
			<PageController pageNumber={currentPage} setPageNumber={setCurrentPage} numPages={numPages} />
		</ProjectsView>
	);
};
