import React from 'react';
import { FormEditor, FormInput, FormDropdown } from '../CreateForm';
import { DateTimeFormInput, DateInputType } from '../DateTimeFormInput';
import {
	MultiSelectContainer,
	MultiSelectLabel,
	CheckBoxContainer,
} from '../MetadataForm';
import { StyledCheckbox } from '../StyledCheckbox';
import {
	ResponsiveFileButton,
	UPLOAD_IMAGE_MIME_TYPES,
} from '../../Users/CreateUserForm';
import { InvisibleInput } from '../Input';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

export const FormFieldCreator = ({
	field,
	currentTemplate,
	parentLabel,
	findDateValue,
	repeaterValueIndex,
	fieldIndex,
	saveRepeaterField,
	saveRepeaterFieldInt
}) => {
	const setFileObject = (e, field) => {
		e.preventDefault();
		const file = e.target.files[0];
		saveRepeaterField(field, file);
	};
	switch (field.type) {
		case 'string':
			return (
				<FormInput
					label={`${field.label}`}
					inputId={`${field.label}-${repeaterValueIndex}-${fieldIndex}`}
					key={`${field.label}-${repeaterValueIndex}-${fieldIndex}`}
					placeholder={field.value}
					width={`49%`}
					
					display='inline-block'
					value={currentTemplate.value[fieldIndex]?.value}
					onChange={(e) =>
						saveRepeaterField(field, e.target.value)
					}
				/>
			);
		case 'int':
		case 'float':
			return (
				<FormInput
					type="number"
					label={field.label}
					key={`${field.label}-${repeaterValueIndex}-${fieldIndex}`}
					placeholder={field.value}
					width="5%"
					display='inline-block'
					labelTextAlign="center"
					value={field.value.toString()}
					onChange={(e) =>
						saveRepeaterFieldInt(field, e.target.value)
					}
				/>
			);
		case 'percent':
			return (
				<FormInput
					type="number"
					label={field.label}
					key={`${field.label}-${repeaterValueIndex}-${fieldIndex}`}
					placeholder={field.value}
					width="49%"
					max={100}
					display='inline-block'
					min={0}
					value={field.value}
					onChange={(e) =>
						saveRepeaterField(field, e.target.value)
					}
				/>
			);
		case 'bool':
			return (
				<FormDropdown
					label={field.label}
					key={`${field.label}-${repeaterValueIndex}-${fieldIndex}`}
					selectedOption={field?.value}
					values={['false', 'true']}
					options={['No', 'Yes']}
					width="49%"
					display='inline-block'
					onChange={(e) =>
						saveRepeaterField(field, e.target.value)
					}
				/>
			);
		case 'dropdown':
			return (
				<FormDropdown
					label={field.label}
					key={`${field.label}-${repeaterValueIndex}-${fieldIndex}`}
					selectedOption={field?.value}
					options={field.options.map((option) => option.display)}
					values={field.options.map((option) => option.value)}
					width="49%"
					display='inline-block'
					placeholder={'-select-'}
					onChange={(e) =>
						saveRepeaterField(field, e.target.value)
					}
				/>
			);
		case 'multiselect':
			return (
				<MultiSelectContainer key={field.label}>
					<MultiSelectLabel>{field.label}</MultiSelectLabel>
					<CheckBoxContainer>
						{field.options.map((option) => (
							<StyledCheckbox
								checkboxId={option.value}
								paddingTop="0"
								key={option.value}
								checked={field.value ? field.value : false}
								width="50%"
								label={option.display}
								onChange={(e) =>
									saveRepeaterField(field,e.target.value)
								}
							/>
						))}
					</CheckBoxContainer>
				</MultiSelectContainer>
			);
		case 'file':
			return (
				<>
					<FileWrapper>
						<ResponsiveFileButton
							title={field.label}
							icon={faPlus}
							alignSelf='center'
							marginLeft='auto'
							margin='auto'
							display='inline-block'
							key={`${field.label}-${repeaterValueIndex}-${fieldIndex}`}
							height="44px"
							onClick={(e) => {
								e.preventDefault();
								let input = document.getElementById(
									`fileUploadInput-${repeaterValueIndex}-${fieldIndex}`
								);
								input.click();
							}}
						/>
						<label>{getFileName(field.value)}</label>
					</FileWrapper>
					<InvisibleInput
						inputType="file"
						display='inline-block'
						accept={UPLOAD_IMAGE_MIME_TYPES}
						id={`fileUploadInput-${repeaterValueIndex}-${fieldIndex}`}
						onChange={(e) => setFileObject(e, field)}
					/>
				</>
			);

		case 'date':
			return (
				<DateTimeFormInput
					label={field.label}
					key={`${field.label}-${repeaterValueIndex}-${fieldIndex}`}
					width="49%"
					format="MM/DD/YYYY"
					placeholder="mm/dd/yyyy"
					type={DateInputType}
					value={findDateValue(field)}
					onChange={(e) =>
						saveRepeaterField(field, e.target.value)
					}
				/>
			);
		case 'paragraph':
			return (
				<FormEditor
					label={field.label}
					key={`${field.label}-${repeaterValueIndex}-${fieldIndex}`}
					value={field?.value}
					id={field.label}
					name={field.label}
					onChange={(content, editor) =>
						saveRepeaterField(field, content)
					}
				/>
			);
		default:
			return <React.Fragment key={field.label}></React.Fragment>;
	}
};
function getFileName(fileValue){
	if(!fileValue){
		return '';
	}else if(fileValue.name){
		return fileValue.name;
	}else{
		return fileValue.split('/')[3];
	}
}
const FileWrapper = styled.div`
	align-self:center;
	margin-bottom:16px;
	margin-left:auto;
	margin-right:auto;
	label{
		margin-left:8px;
	}
`;