import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {Color, FontSize, FontWeight} from '../../constants';
import {StyledText} from './StyledText';
import moment from 'moment';
import { Button } from './Button';
import { DataContext } from '../../data-context';
import { config } from '../../config';
import { API } from '../../projlibs/api';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

// Metadata: Displays 2 StyledText, a title and it's value
// Props:
// title: title to display.
// value: value to display.
// type: type of value to display.  String, int, float, date, paragraph
const MetadataView = styled.div`
	padding-bottom: 1rem;
	${props=>css`
		width: ${props.width};
		display: ${props.display};
		min-width: ${props.minWidth};
		margin-top:${props.marginTop};
	`};

	.p-value {
		p, li {
			font-weight: ${FontWeight.normal};
		}
		> p:first-child {
			margin-top:0px;
		}
	}
`;

const Title = styled(StyledText)``;

const Value = styled(StyledText)``;

export const Metadata = (props) => {
	const context = useContext(DataContext);
	const [fileValue, setFileValue] = useState('-');
	useEffect(()=>{
		if(props.type==='file' && typeof props.value ==='string'){
			context.aws.getSignedUrl(config.bucket_name, props.value, err => {
				API.default_error_handler(err);
			}, success => {
				setFileValue(<Button iconSize='2x' paddingLeft={0} icon={faFileDownload}onClick={()=>window.open(success)}/>);
			});
		}
	},[context.aws, props.type, props.value]);
	const type=props?.type;
	let value=props?.value;
	if(type==='bool'){
		value=(props.value==='true'?'Yes':'No');
	}
	else if(type==='multiselect' && value.length > 0){
		value = value.join(", ")
	}else if(type==='date'){
		value=value?moment.unix(props.value).utc().format('MMM. DD, YYYY'):'-';
	}else if(type==='file'){
		value=fileValue;
	}else if(!value){
		value='-';
	}
	return (
		<MetadataView {...props}>
			<Title>{props.label}</Title>
			{(props?.type==='paragraph')?
				<div className='p-value' dangerouslySetInnerHTML={{ __html: (value) }} />
			:
				<Value>
					{value}
				</Value>
			}
		</MetadataView>
	);
};

Metadata.defaultProps = {};

Title.defaultProps = {
	color: Color.nile50,
	fontSize: FontSize.body2,
	marginBottom: '0.25rem'
};

Value.defaultProps = {
	color: Color.nile,
	fontSize: FontSize.body1
};

export const MetaDataRepeater=({metaData})=>{
	return <>{(metaData.map((item, index)=>{
		return <Wrapper key={`repeater-wrapper-${index}`}>{item.value.map((element,index)=>
			<Metadata marginTop={index===0?'16px':0}minWidth='40px' display={element.type==='int'?'inline-block':''} width={element.type==='int'?'10%':''}key={`${element.label}-${index}`}{...element}/>
		)
	}</Wrapper>;}))}</>;
};
const Wrapper = styled.div`
	border-top: 1px solid;
	margin-top:16px;
`;