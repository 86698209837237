import React from 'react';
import { FormFieldCreator } from './FormFieldCreator';
import { PrimaryButton } from '../Button';
import styled from 'styled-components';
import { breakpoint } from '../../../breakpoints';
export const Repeater = ({
	form,
	currentTemplate,
	saveValue,
	values,
	findDateValue,
	addRepeater,
	saveRepeaterField,
	saveRepeaterFieldInt,
	repeaterIndex
}) => {
	return (
		<Wrapper>
			{currentTemplate.value.map((item, index) => {
				return <RepeaterWrapper key={`wrapper-${index}`}>{item.value.map((innerItem, innerIndex) => {
					return (
							<FormFieldCreator
								key={`repeater-${index}-${innerIndex}`}
								repeaterIndex={repeaterIndex}
								repeaterValueIndex={index}
								saveRepeaterFieldInt={saveRepeaterFieldInt}
								saveRepeaterField={saveRepeaterField}
								index={index}
								parentLabel={item.label}
								fieldIndex={innerIndex}
								field={innerItem}
								saveValue={saveValue}
								currentTemplate={item}
								findDateValue={findDateValue}
							/>
					);
				})}</RepeaterWrapper>;
			})}

			<PrimaryButton
				type="button"
				onClick={()=>addRepeater()}
				title="Add More"
				width='100%'
				marginTop='16px'
				marginBottom='16px'
			/>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	width: 100%;
	height:100%;
`;
const RepeaterWrapper = styled.div`
	border-bottom: 1px solid;
	margin-bottom:16px;
	width:100%;
	height:100%;
	display: flex;
	flex-wrap: wrap;
	padding: 5px;
	justify-content: space-between;
	text-align: left;
	${breakpoint('small only')} {
		flex-direction: column;
	}
`;