import React from 'react';
import CRMRAAWS from "./projlibs/awsMiddleLayer";

//The Data Model State is stored as a React Context to avoid having to pass it down manually to everything which uses it
//NOTE: this could also be done with Redux
//initially we set blank, null, or other sane default values but these will be overwritten as data from network calls is received
const DataContext = React.createContext({
	//login session
	userSessionId: '',
	setSessionId: () => {},
	userId: 0,
	setUserId: () => {},
	userImageURL: '',
	setUserImageURL: () => {},
	username: '',
	setUserName: () => {},
	userColour: '',
	setUserColour: () => {},
	userRoles: [],
	setUserRoles: () => {},
	projectStatuses: {},
	allStatuses: [],
	quoteStatuses: [],
	
	//aws
	aws: new CRMRAAWS(),
	
	//overlay
	overlayIsOpen: false,
	setOverlayVisibility: () => {},
	
	//current user permissions
	userPerms: {},
	setUserPerms: () => {},

	alerts: [],
	getAlerts: () => {},
	getAlertsByUserId: () => {},

	//project list
	projects: [],

	defaultQuoteStatusOrder: [
		'Quote Inquiry',
		'Quote New Request',
		'Quote in Progress',
		'Quote Info Required',
		'Quote Ready',
		'Quote Waiting',
		'Quote Rejected',
		'Archive'
	],

	defaultStatusOrder: [
		'Design New Request',
		'Design In Progress',
		'Design Ready for Review',
		'Design Waiting Approval',
		'Design Changes Required',
		'Design Approved',
		'Design Emb Digitization Required',

		'Screen Ready to Burn',
		'Screen Ready to Print',
		'Screen In Progress',
		'Screen On Hold',

		'Embroidery Ready to Run',
		'Embroidery In Progress',
		'Embroidery On Hold',

		'Promo Ready to Order',
		'Promo Out Sourced',
		'Promo On Hold',


		'Ready to Bill',
		'Billed',
		'Production Complete',
		'Shipping Required',
		'Delivery Required',
		'Customer Pick-Up',
		'Thank You',
		'Archive'
	]

});

//displayName is used internally by React Context for dev tools views
//to make debugging easier
DataContext.displayName = 'CRM Data Context';

export { DataContext };
