import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Color, FontSize, FontWeight, PROJECT, QUOTE} from '../../constants';
import {StyledBase} from '../Shared/StyledBase';
import {StyledText} from '../Shared/StyledText';
import {FormattedDate} from '../Shared/FormattedDate';
import {API, useGetData} from "../../projlibs/api";
import S3ImageController from "../Shared/S3ImageController";
import {StyledInitials} from "../Shared/StyledInitials";
import {formatText} from "../../projlibs/helperFunctions";

// Logs: displays activity logs.
// Props:
// logs: array of logs.
const LogsView = styled.div`
	${StyledBase}
	min-width: 0%;
	overflow: auto;
`;

const LogCell = styled.div`
	display: flex;
	padding: 16px 0px;
	border-bottom: 1px solid ${Color.nile10};
`;

const HorizontalDataView = styled.div`
	.inner-html {
		p, li {
			font-weight: ${FontWeight.normal};
		}
	}
	margin-left: 17px;
`;
export const getField = (rawActivity) => {
	if(rawActivity.field_changed === 'status_set_at'){
		return;
	}
	if(rawActivity.field_changed === 'project_status_id' || rawActivity.field_changed === 'client_status_id'){
		return 'Status';
	}
	if(rawActivity.field_changed === 'is_quote'){
		return rawActivity.new_value === 'true' ? `Quote` : 'Project';
	}
	if(rawActivity.field_changed === 'project_type_id') {
		return 'Project Type';
	}
	if(rawActivity.field_changed.startsWith('date')) {
		return rawActivity.field_changed.replace('_', ' ');
	}
	if(rawActivity.field_changed.startsWith('meta.')){
		return rawActivity.field_changed;
	}
	if(rawActivity.field_changed === ('assigned_users')) {
		return 'Assigned Users';
	}
	if(rawActivity.field_changed === ('project_manager')) {
		return 'Project Manager';
	}
	if(rawActivity.field_changed === ('delivery_price')) {
		return 'Delivery Price';
	}
	if(rawActivity.field_changed === ('primary_contact_id')) {
		return 'Primary Contact';
	}
	if(rawActivity.field_changed === ('primary_location_id')) {
		return 'Primary Location';
	}

	return formatText(rawActivity.field_changed);
}

export const getNewValue = async (rawActivity, name, types, users, projectStatuses, clientStatuses, id) => {
	if(rawActivity.field_changed === 'status_set_at'){
		return;
	}
	if(rawActivity.field_changed === 'project_status_id'){
		const status = projectStatuses.Project_Status?.filter(status => status.project_status_id === parseInt(rawActivity.new_value))[0];
		return formatText(status?.name);
	}
	if(rawActivity.field_changed === 'client_status_id'){
		const status = clientStatuses.Client_Status?.filter(status => status.client_status_id === parseInt(rawActivity.new_value))[0];
		return formatText(status?.name);
	}
	if(rawActivity.field_changed === 'is_quote'){
		return rawActivity.new_value === 'true' ? 'approved' : 'unapproved';
	}
	if(rawActivity.field_changed === 'project_type_id') {
		const type = types.Project_Type?.filter(type => type.project_type_id === parseInt(rawActivity.new_value))[0];
		return type?.name;
	}
	if(rawActivity.field_changed.startsWith('date')) {
		if(rawActivity.new_value === null){
			return 'removed'
		}
		return rawActivity.new_value.split(' ')[0];
	}
	if(rawActivity.field_changed === ('project_manager')) {
		const user = users.User?.filter(user => user.user_id === parseInt(rawActivity.new_value))[0];
		return user?.full_name;
	}
	if(rawActivity.field_changed === ('assigned_users')) {
		const assignedUsers = users.User?.filter(user =>
			rawActivity.new_value.includes(`,${user.user_id},`) || rawActivity.new_value.includes(`{${user.user_id},`) || rawActivity.new_value.includes(`,${user.user_id}}`));
		return assignedUsers?.map(user => user.full_name).join(', ');
	}
	if(rawActivity.field_changed === ('primary_contact_id')) {
		const resp = await API.get(`/client/${id}/contact/${rawActivity.new_value}`)
		return resp.data.Contact[0]?.name;
	}
	if(rawActivity.field_changed === ('primary_location_id')) {
		const resp = await API.get(`/client/${id}/location/${rawActivity.new_value}`)
		return resp.data.Location[0]?.address;
	}
	return rawActivity.new_value;
};

export const convertToText = (rawActivities, name, projectTypes, userData, projectStatuses, clientStatuses, id) => {
	const activityPromises = rawActivities.Activity?.map(rawActivity => {
		return getNewValue(rawActivity, name, projectTypes, userData, projectStatuses, clientStatuses, id).then(value => {
			return {
				img_s3_path: rawActivity.User?.img_s3_path,
				user_name: rawActivity.User?.full_name,
				created_at: rawActivity.created_at,
				field: getField(rawActivity),
				new_value: value,
				name: name,
			};
		});
	});
	return Promise.all(activityPromises);
};

export const Logs = (props) => {
	const getUrl = `/${props.type === PROJECT || props.type === QUOTE ? 'project' : 'client'}/${props.id}/activity`;
	let [{data: rawActivities}] = useGetData(getUrl);
	const [{ data: projectTypes }] = useGetData("/project/type");
	const [{ data: userData }] = useGetData("/user?page_size=");
	const [{ data: projectStatuses }] = useGetData("/project/status?is_quote="+((props.type === PROJECT) ? "false" : "true"));
	const [{ data: clientStatuses }] = useGetData("/client/status");
	const [activities, setActivities] = useState([]);
	rawActivities = rawActivities === null ? [] : rawActivities;

	useEffect(() => {
		if(rawActivities && rawActivities.Activity){
			convertToText(rawActivities, props.name, projectTypes, userData, projectStatuses, clientStatuses, props.id).then(activities => {
				setActivities(activities);
			});
		}
	}, [rawActivities, props.name, projectTypes, userData, projectStatuses, clientStatuses, props.id])

	return (
		<LogsView {...props}>
			{activities?.filter(activity => activity.field !== undefined).map((log) => (
				<LogCell key={log.field + log.new_value + log.created_at}>
					{log.img_s3_path ?
						<S3ImageController
							s3_path={log.img_s3_path}
							width='50px'
							height='50px'
							borderRadius='50%'
							marginRight='16px'
						/>
						:
						<StyledInitials
							size='50px'
							marginTop='0'
							marginBottom='0'
							marginRight='16px'
							fontSize={FontSize.body1}
							borderRadius='50%'
							backgroundColor={Color.zest}
							numberOfInitials={2}>
							{log.name}
						</StyledInitials>
					}
					<HorizontalDataView>
						{log.field.includes('meta.repeater')?
							<StyledText fontWeight={FontWeight.normal} display="inline-block" fontSize={FontSize.body1}
								color={Color.nile}>
							<b>{log.user_name}</b> updated {log.field.replace(/meta\..*\./, '')} on <b>{log.name}</b>
						</StyledText>
						:
						log.field.includes('meta.') ?
							<StyledText fontWeight={FontWeight.normal} display="inline-block" fontSize={FontSize.body1}
										color={Color.nile}>
								<b>{log.user_name}</b> changed {log.field.replace(/meta\..*\./, '')} to {log.new_value?.includes('<p>') ? <span className='inner-html' dangerouslySetInnerHTML={{__html: log.new_value}} /> : <b>{log.new_value}</b>} on <b>{log.name}</b>
							</StyledText>
							:
							<StyledText fontWeight={FontWeight.normal} display="inline-block" fontSize={FontSize.body1}
										color={Color.nile}>
								<b>{log.user_name}</b> changed {log.field} to {log.new_value?.includes('<p>') ? <span className='inner-html' dangerouslySetInnerHTML={{__html: log.new_value}} /> : <b>{log.new_value}</b>} on <b>{log.name}</b>
							</StyledText>
						}
						<FormattedDate
							date={log.created_at}
							fontSize={FontSize.body2}
							color={Color.nile50}
							marginTop='4px'
						/>
					</HorizontalDataView>
				</LogCell>
			))}
		</LogsView>
	);
}
