import React from 'react';
import styled from 'styled-components';
import { FormattedDate } from '../Shared/FormattedDate';
import { Color, MS_PER_S } from '../../constants';
import {
	DateTimeFormInput,
	DateTimeInputType,
} from '../Shared/DateTimeFormInput';
import moment from 'moment';
import { EditButton } from '../Activity/NoteCell';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
export const TableCalendarButton = ({project,UpdateProjectDateDue,setRefresh}) => {
	const clickHandler=()=>{
		const calendar = document.getElementById(`date-time-${project.project_id}`);
		if(calendar){
			calendar.click();
		}
	};
	return (
		<>
			<DateWrapper onClick={clickHandler}>
				<FormattedDate date={project.date_due} color={Color.nile50} />
				{!project.date_due && <EditButton onClick={clickHandler} hoverBackground={Color.fuel} icon={faEdit} />}
			</DateWrapper>
			<HiddenWrapper>
				<DateTimeFormInput
					label=""
					inputId={`date-time-${project.project_id}`}
					format="MM/DD/YYYY"
					placeholder="mm/dd/yyyy"
					value={project.date_due * MS_PER_S}
					width="100%"
					acceptDate={(date) => {
						const newDate = moment(date, 'YYYY-MM-DDTHH:mm').unix();
						UpdateProjectDateDue(newDate, project.project_id);
						setRefresh((prev) => !prev);
					}}
					type={DateTimeInputType}
				/>
			</HiddenWrapper>
		</>
	);
};

const DateWrapper = styled.div`
	cursor: pointer;
	display:flex;
	align-items:center;
`;
const HiddenWrapper = styled.div`
	display:none;
`;