import React from 'react';
import styled, {css} from 'styled-components';
import {StyledText} from './StyledText';
import {StyledBase} from './StyledBase';
import {Input} from '../Shared/Input';
import {Dropdown} from '../Shared/Dropdown';
import {BackButton, PrimaryButton, UnderlineLink} from '../Shared/Button';
import {Color, FontSize, MS_PER_S} from '../../constants';
import {breakpoint} from '../../breakpoints';
import {faArrowLeft, faTimes} from '@fortawesome/free-solid-svg-icons';
import {TinyMCEEditor} from "./TinyMCEEditor";
import moment from "moment";


export const getNewMetaInfo = (value, projectTypes) => {
	if(projectTypes.Project_Type){
		const metadata = projectTypes.Project_Type.find(type => type.name === value).project_meta_template;
		const metaFields = metadata.map(metaObject => {
			return {
				"meta_value": extractTypeAndLabel(metaObject.meta_value, true),
				"meta_name": metaObject.meta_name,
				"project_meta_template_id": metaObject.project_meta_template_id,
				"display_order": metaObject.display_order,
				"project_type_id": metaObject.project_type_id,
				"is_changed": true,
			};

		});
		return [{metadata, metaFields}];
	}
	return ["", ""]
 };

export const extractTypeAndLabel = (meta_value, newForm=false) => {
	if(meta_value) {
		return meta_value.map(field => {
			if(newForm && field.type==='repeater'){
				return {
					"label": field.label,
					"value": [{"value": field.value?field.value:[]}],
					"type": field.type,
				};
			}else{
				return {
					"label": field.label,
					"value": field.value ? field.value : "",
					"type": field.type,
				};
			}
		});
	}
}

export const buildProject = (
    projectType,
    client,
    projectName,
    dueDate,
    dateQuoted,
    contactDate,
    metaFields,
	assignedUsers,
	retailPrice,
	deliveryPrice,
	setupFee,
	discount,
	actualHours,
	hourlyRate,
	isUsingTimestamps = false,
	copiedFromId=null,
) => {

	const numberField = ["int", "float", "percent", "date"];
	metaFields.forEach(metaObject => metaObject.meta_value.forEach(field => {
		if(field.value instanceof Date){
			field.value = field.value.getTime() / MS_PER_S;
		}

		if(numberField.includes(field.type) && field.value === ""){
			field.value = 0;
		}	
	}));

	const project = {
		"project_type": projectType,
		"client_id": client,
		"name": projectName,
		"date_due": isUsingTimestamps ? dueDate : moment(dueDate, 'YYYY-MM-DDTHH:mm').unix(),
		"date_quoted": isUsingTimestamps ? dateQuoted : moment(dateQuoted, 'YYYY-MM-DD').unix(),
		"date_initial_contact": isUsingTimestamps ? contactDate : moment(contactDate, 'YYYY-MM-DD').unix(),
		"project_meta": metaFields,
		"assigned_users": assignedUsers.map(user => user.value),
		"retail_price": typeof retailPrice === 'string' ? 0 : retailPrice,
		"delivery_price": typeof deliveryPrice === 'string' ? 0 : deliveryPrice,
		"setup_fee": typeof setupFee === 'string' ? 0 : setupFee,
		"discount": typeof discount === 'string' ? 0 : discount,
		"actual_hours": typeof actualHours === 'string' ? 0 : actualHours,
		"hourly_rate": typeof hourlyRate === 'string' ? 0 : hourlyRate,
		"copied_from_id": (copiedFromId!==null)?parseInt(copiedFromId):null,
	}

	return project;
}

export const Overlay = styled.div`
	padding: 1.5rem;
	height: calc(100% - 6rem);
	width: fit-content;
	background-color: ${Color.white};
	position: relative;
	border-radius: 4px;
	box-shadow: 0 1px 24px 0 ${Color.nile18};
	margin: 1.5rem auto 0;
	overflow-y:auto;
	${breakpoint('small only')} {
		width: auto;
		margin: 0;
		top: 2.5%;
	}
	${StyledBase}
`;

export const CreateForm = styled.form`
	display: flex;
	flex-wrap: wrap;
	padding: 5px;
	justify-content: space-between;
	text-align: left;
	${breakpoint('small only')} {
		flex-direction: column;
	}

`;

export const Title = styled(StyledText)``;

Title.defaultProps = {
	color: Color.darkBlue,
	fontSize: '27px',
	marginBottom: '32px'
};

export const HeaderView = styled.div`
	display: inline-flex;
	width: 100%;
`;

export const HeaderExitInlineView = styled.div`
	display: inline-flex;
	justify-content: space-between;
	width: 100%;
	${Title} {
		font-size: ${FontSize.header3};
	}
`;

export const Header = (props) => (
	<HeaderView>
		<BackButton onClick={props.onClose} icon={faArrowLeft}></BackButton>
		<Title>{props.title}</Title>
	</HeaderView>
)

export const HeaderExitInline = (props) => (
	<HeaderExitInlineView>
		<Title paddingLeft='5px'>{props.title}</Title>
		<BackButton paddingRight='0' onClick={props.onClose} iconColor={Color.regent} icon={faTimes}></BackButton>
	</HeaderExitInlineView>
);

export const EditorView = styled.div`
	${props => css`
		label {
			display: block;
			color: ${Color.nile50};
			font-size: ${FontSize.body2};
		}	
		margin-bottom: 24px;
		width: 100%
	`}
`;

export const FormEditor = (props) => (
	<EditorView>
		{props.label && <label htmlFor={props.inputId}>{props.label}</label>}
		<TinyMCEEditor {...props} />
	</EditorView>
)

export const FormInput = styled(Input)`
	${(props) => css`
		width: 100%;
		padding: ${props.padding};
		max-width: ${props.maxWidth};
		min-width: ${props.minWidth};
		${breakpoint('medium up')} {
			width: ${props.width}
		}
	`};
`;

FormInput.defaultProps = {
	marginBottom: '24px',
	height: '44px'
};

export const FormDropdown = styled(Dropdown)`
	${(props) => css`
		width: 100%;
		max-width: ${props.maxWidth};
		min-width: ${props.minWidth};
		${breakpoint('medium up')} {
			width: ${props.width}
}
`};
`;

FormDropdown.defaultProps = {
	fontSize: FontSize.body1,
	marginBottom: '12px',
	height: '44px',
	divHeight: '73px',
};

export const SubHeading = styled(UnderlineLink)`
	border-bottom: 1px solid ${Color.nile10};
	line-height: 1;
	padding-bottom: 15px;
	width: 100%;
	color: ${Color.darkBlue};
	margin-top: 16px;
	margin-bottom: 16px;
	font-size: ${FontSize.header5};
	&::after {
		right: 1rem;
	}
`;

export const AdditionalFields = styled(UnderlineLink)`
	border-bottom: 1px dotted;
	line-height: 1;
	padding-bottom: 2px;
	margin-bottom: 32px;
	color: ${Color.darkBlue};
	font-size: ${FontSize.body1};
`;

export const SubmitButton = styled(PrimaryButton)`
	margin-top: 24px; 
	margin: auto; 
	width: 385px; 
	height: 56px; 
	display: block;
	font-size: ${FontSize.body1}
	${StyledBase}
	${breakpoint('small down')}{
		width:100%;
	}
`;
