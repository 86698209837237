import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import {AWS_IDENTITY_ID, AWS_TOKEN, Color, NAV_LOGIN, NAV_OVERVIEW, SESSION_ID, USER_ID} from '../../constants';
import {Image} from '../Shared/Image';
import {breakpoint} from '../../breakpoints';
import {API} from '../../projlibs/api';
import {DataContext} from '../../data-context';
import {set_cookie} from '../../projlibs/cookie-management';
import {useHistory} from 'react-router-dom';
import {SignIn} from './SignIn';
import {ForgotPassword} from './ForgotPassword';
import {ResetPassword} from './ResetPassword';
import {success, warning} from '../../projlibs/feedback';

const SIGN_IN = 'sign_in';
const FORGOT_PASS = 'forgot_pass';

const LoginView = styled.div`
    height: 100vh;
    background-color: ${Color.silver};
    width: 100vw;
    display: flex;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const LogoView = styled.div`
    background: ${Color.black};
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    ${breakpoint('medium up')} {
        flex: 2;
    }
`;

const FormView = styled.div`
    height: fit-content;
    overflow: auto;
    flex: 4;
    background-color: ${Color.silver};
    display: flex;
    justify-content: center;
    ${breakpoint('medium up')} {
        height: 100%;
        flex: 3
    }
`;

const LogoImage = styled(Image)`
    margin-top: auto;
    margin-bottom: auto;
    height: 50px;
    width: 250px;
    object-fit: fill;
`;

export const LoginController = (props) => {
    const [page, setPage] = useState(SIGN_IN);
    const [errors, setErrors] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [resetPassErrors, setResetPassErrors] = useState([]);
    const [newPassErrors, setNewPassErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const history = useHistory();
    const context = useContext(DataContext);
    const setSessionId = context?.setSessionId;
    const setUserName = context?.setUserName;
    const setUserColour = context?.setUserColour;
    const setUserImageUrl = context?.setUserImageURL;
    const setUserId = context?.setUserId;
    const setUserRoles = context?.setUserRoles;
    const setUserPerms = context?.setUserPerms;
    const getAlerts = context?.getAlerts;
    const getProjectStatuses = context?.getProjectStatuses;
    const getQuoteStatuses = context?.getQuoteStatuses;
    const initAWSInstance = context?.aws.initAWSInstance;
    const getProjectList = context?.getProjectList;
    const isResetPage = props.location.pathname.includes('reset');
    const queryValues = queryString.parse(props.location.search);
    // Moved from App.js
    const SubmitLogin = () => {
        setLoading(true);
        API.post('/login', {
            data: { email: username, password: password },
        }).then(response => {
            setUserName(response.data.User.full_name);
            setUserColour(response.data.User.colour);
            setUserImageUrl(response.data.User.img_s3_path);
            const userId = response.data.Session?.user_id;
            const sessionId = response.data.Session?.session_id;
            const awsTokenIdentityId = response.data.AWSToken?.IdentityId;
            const awsToken = response.data.AWSToken?.Token;
            const roles = response.data.User.groups?.map(group => group.group_name);
            let permissions = {};
            response.data.User.groups.forEach(group => permissions = {...permissions, ...group.permissions});
            set_cookie(SESSION_ID, sessionId);
            set_cookie(USER_ID, userId);
            set_cookie(AWS_TOKEN, awsToken);
            set_cookie(AWS_IDENTITY_ID, awsTokenIdentityId);
            setUserPerms(permissions);
            setUserRoles(roles);
            setUserId(userId);
            setSessionId(sessionId);
            getProjectList();
            getProjectStatuses();
            getQuoteStatuses();
            getAlerts();
            initAWSInstance(awsTokenIdentityId, awsToken).then(() => {
                history.replace(NAV_OVERVIEW);
            }).catch(() => {
                warning('We could not connect to Amazon Web Services, some features may be disabled.');
                history.replace(NAV_OVERVIEW);
            });
        }).catch(error => {
            if (error?.message?.includes('401')) {
                setErrors(['Incorrect username or password. Check your credentials.']);
            } else if (error?.message?.includes('429')) {
                setErrors(['Too many attempts, please contact support']);
            } else {
                console.log(error);
                setErrors(['Sorry, we could not reach the login servers, please try again later.'])
            }
            setLoading(false);
        });
    };

    const SubmitResetEmail = () => {
        setLoading(true);
        API.post('/request-reset', { data: { email: resetEmail } }).then(() => {
            setLoading(false);
            setNotifications(['Reset email successfully sent.']);
            setPage(SIGN_IN);
        }).catch(error => {
            setLoading(false);
            if (error?.message?.includes('429')) {
                setResetPassErrors(['Too many attempts, please contact support']);
            } else if (error?.message?.includes('400')) {
                setResetPassErrors(['Email could not be sent, please make sure this is a valid email address.']);
            } else {
                setResetPassErrors(['Sorry we are having trouble sending the reset email, please try again later.'])
            }
        })
    };

    const SubmitNewPassword = () => {
        setLoading(true);
        if (newPassword === confirmPassword) {
            API.post('/reset', {
                data: {
                    password1: newPassword,
                    password2: confirmPassword,
                    reset_code: queryValues.code
                }
            }).then(() => {
                setLoading(false);
                success('Password changed successfully');
                history.push(NAV_LOGIN);
            }).catch(error => {
                setLoading(false);
                if (error.message.includes('500')) {
                    setNewPassErrors(['Sorry we are having trouble resetting your password, please try again later.']);
                } else if (error.message.includes('400')) {
                    setNewPassErrors(['Password does not meet required criteria.']);
                } else {
                    setNewPassErrors([error.message]);
                }
            });
        } else {
            setLoading(false);
            setNewPassErrors(['Passwords must match.'])
        }
    };

    return (
        <LoginView>
            <LogoView>
                <LogoImage src='https://teamoutfitters.net/wp-content/uploads/2021/10/Team-Logo-White.png'/>
            </LogoView>
            <FormView>
                {
                    (page === SIGN_IN && !isResetPage) &&
                    <SignIn
                        notifications={notifications}
                        setUsername={setUsername}
                        setPassword={setPassword}
                        errors={errors}
                        loading={loading}
                        setPage={setPage}
                        SubmitLogin={SubmitLogin}
                    />
                }
                {
                    (page === FORGOT_PASS && !isResetPage) &&
                    <ForgotPassword
                        errors={resetPassErrors}
                        loading={loading}
                        resetEmail={resetEmail}
                        setResetEmail={setResetEmail}
                        setPage={setPage}
                        SubmitResetEmail={SubmitResetEmail}
                    />
                }
                {
                    isResetPage &&
                    <ResetPassword
                        resetCode={queryValues.code}
                        loading={loading}
                        errors={newPassErrors}
                        newPassword={newPassword}
                        confirmPassword={confirmPassword}
                        setNewPassword={setNewPassword}
                        setConfirmPassword={setConfirmPassword}
                        SubmitNewPassword={SubmitNewPassword}
                    />
                }
            </FormView>
        </LoginView>
    );
};
