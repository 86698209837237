import React, {useContext, useState} from 'react';
import styled, {css} from 'styled-components';
import {Color, FontSize, FontWeight} from '../../constants';
import {Image} from '../Shared/Image';
import {StyledText} from '../Shared/StyledText';
import {FormattedDate} from '../Shared/FormattedDate';
import {Button} from '../Shared/Button';
import S3ImageController from '../Shared/S3ImageController';
import {StyledInitials} from "../Shared/StyledInitials";
import {faCheck, faEdit, faThumbtack, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {TinyMCEEditor} from '../Shared/TinyMCEEditor';
import {API} from '../../projlibs/api';
import {DataContext} from '../../data-context';
import {success} from '../../projlibs/feedback';
import { DeleteButton } from '../Shared/OverlayViewButton';
import { DeleteConfirmation } from '../Shared/DeleteConfirmation';
import { OverlayView } from '../Shared/OverlayView';

const NoteCellView = styled.div`
	display: flex;
	align-items: center;
	border-radius: 5px;
	padding-top: 8px;
	padding-left: 8px;
	${props => css`
		transition: background-color 0.3s ease-in-out;
		background-color: ${props.pinned ? Color.banana : Color.transparent};
	`}

	${Image} {
		align-self: flex-start;
		flex-shrink: 0;
	}
`;

const ContentView = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 16px;
	width: 525px;
`;

const NameDateView = styled.div`
	display: flex;
	margin-bottom: 8px;
`;

const NoteButtonsView = styled.div`
	margin-left: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
`;

export const EditButton = styled(Button)`
    background-color: ${Color.transparent};
    margin: 0 0.2rem;
    height: 35px;
    min-height: 35px;
    width: 35px;
    min-width: 35px;
    border-radius: 50%;
    border-color: ${Color.transparent};
`;

const NoteButton = styled(DeleteButton)`
    ${OverlayView} {
        border-radius: 50%;
        width: 100%;
        max-width: 100vw;
        height: unset;
    }
`;

const StyledNoteText = styled.div`
	p {
        display: flex;
        flex-direction: column !important;
        align-self: flex-start;
		margin: 0 0 0.5rem 0;
		font-weight: ${FontWeight.normal};
	}
	em, li {
		font-weight: ${FontWeight.normal};
	}
`;

export const NoteCell = ({ description, createdAt, pinned, userImage, fullName, pinNote, deleteNote, noteId, updateNotes, note, disableEdit, colour }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editDescription, setEditDescription] = useState(description);
    
    const context = useContext(DataContext);
    
    const handleUpdateDescription = () => {
        setIsEditing(false);
        API.put(`/note/${noteId}`, {
            data: {
                description: editDescription,
            },
        }).then(() => {
            success('Successfully updated note.');
            updateNotes();
        }).catch(API.default_error_handler);
    };
    
    const editingUserId=parseInt(context.userId);
    const noteOwnerId=parseInt(note.uploaded_by);
    
    return (
        <NoteCellView key={description + createdAt} pinned={pinned}>
            {userImage ?
                <S3ImageController
                    s3_path={userImage}
                    width='48px'
                    height='48px'
                    borderRadius='50%'
                />
            :
                <StyledInitials
                    size='46px'
                    width='46px'
                    height='46px'
                    alignSelf='flex-start'
                    marginTop='0'
                    marginBottom='0'
                    borderRadius='50%'
                    fontSize={FontSize.header4}
                    numberOfInitials={2}
                    backgroundColor={colour ? colour : Color.zest}>
                    {fullName}
                </StyledInitials>
            }
            <ContentView>
                <NameDateView>
                    <StyledText
                        fontSize={FontSize.body1}
                        color={Color.nile}
                        marginRight='12px'>
                        {fullName}
                    </StyledText>
                    <FormattedDate
                        date={createdAt}
                        fontSize={FontSize.body2}
                        color={Color.nile50}
                    />
                    {
                        !disableEdit &&
                        <NoteButtonsView>
                            {
                                isEditing ?
                                <EditButton onClick={handleUpdateDescription} hoverBackground={Color.meadow} icon={faCheck} /> :
                                ((editingUserId===noteOwnerId) &&
                                    <EditButton onClick={() => setIsEditing(true)} hoverBackground={Color.fuel} icon={faEdit} />
                                )
                            }
                            <EditButton onClick={() => pinNote(noteId, !pinned)} hoverBackground={Color.banana} icon={faThumbtack} />
                            {(editingUserId===noteOwnerId) &&
                                <NoteButton hoverBackground={Color.coral} icon={faTrash} location='sss'>
                                    <DeleteConfirmation onDelete={() => deleteNote(noteId)} />
                                </NoteButton>
                            }
                        </NoteButtonsView>
                    }
                </NameDateView>
                {
                    isEditing ?
                    <TinyMCEEditor id={`edit-note-${noteId}`} value={editDescription} onChange={(content, edit) => setEditDescription(content)} /> :
                    <StyledNoteText
                        display='flex'
                        flexDirection='column'
                        fontSize={FontSize.body1}
                        color={Color.nile}
                        dangerouslySetInnerHTML={{__html: description}}
                        >
                    </StyledNoteText>
                }
            </ContentView>
        </NoteCellView>
    );
};
